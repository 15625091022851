var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canVerb('book_entries', 'index'))?_c('div',{staticClass:"mx-1 mb-3"},[_c('div',{staticClass:"mt-2"},[_c('b-form',[_c('b-card',{staticClass:"filter"},[_c('p',[_c('span',[_vm._v("Modalità: "+_vm._s(_vm.progressiveLabel))]),_vm._v(" "),(_vm.isProgressive)?_c('span',[_vm._v("\n            :: Data Ultimo Consolidamento:\n            "+_vm._s(_vm.customFormatDate(_vm.consolidatedAt))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.isProgressive && _vm.previouslyConsolidatedAt)?_c('span',[_vm._v("\n            :: Data Consolidamento Precedente:\n            "+_vm._s(_vm.customFormatDate(_vm.previouslyConsolidatedAt))+"\n          ")]):_vm._e()]),_vm._v(" "),_c('b-row',[_c('div',{staticClass:"col-md-3"},[_c('base-datepicker',{attrs:{"vid":"detail_date","name":"detail_date","label":"Data","min":_vm.minDate},on:{"select":_vm.onDetailDateSelect},model:{value:(_vm.filter.byCalendar.from),callback:function ($$v) {_vm.$set(_vm.filter.byCalendar, "from", $$v)},expression:"filter.byCalendar.from"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-6 align-self-end"},[_c('b-button',{attrs:{"type":"button","variant":"lisaweb","size":"sm","disabled":!_vm.canGenerate || !_vm.canConsolidate},on:{"click":function($event){// #686
                _vm.previously_consolidated_at = null;
                _vm.drawFoglioQuadrature();}}},[_vm._v("Genera\n            ")]),_vm._v(" "),_c('b-button',{attrs:{"variant":"lisaweb"},on:{"click":_vm.onViewConsCal}},[_c('b-icon',{attrs:{"icon":"calendar"}}),_vm._v("\n              Consolidamenti\n            ")],1)],1)])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"mt-2 mb-4"},[_c('export-options',{attrs:{"exportUrl":_vm.exportUrl,"repository":_vm.repository,"resource":_vm.resource,"tableRef":_vm.$refs[_vm.tableRef],"filter":_vm.filter,"options":[
        {
          code: 'FOGQUAD',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]}}),_vm._v(" "),_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}],null,false,1652880295)},[(!_vm.isLoading && Object.keys(_vm.detailsData).length)?_c('div',[(_vm.isProgressive)?_c('b-card',{staticClass:"mt-1",attrs:{"header":'Totale Progressivi consolidati al ' +
            _vm.toLocaleDate(
              !_vm.previouslyConsolidatedAt
                ? _vm.consolidatedAt
                : _vm.previouslyConsolidatedAt
            ),"header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableConsolidatedBySector).length)?_c('table',[_c('thead',[_c('tr',[_c('th',{class:{ mw: true, verticalLine: true }},[_vm._v("Compagnia")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N',
                      'title'
                    )),function(item){return _c('th',{key:item.code,class:{ sw: true }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Oneri Vari")])],2)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.tableConsolidatedBySector),function(sectorGross,id){return _c('tr',{key:'tr-' + id},[_c('td',{staticClass:"verticalLine"},[_vm._v("\n                    "+_vm._s(parseInt(id)
                        ? _vm.getInsurers().find((e) => e.value == id).text
                        : "Indefinita")+"\n                  ")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(sectorGross[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N'
                    )),function(code,index2){return _c('td',{key:'2.' + index2,class:{
                      verticalLine:
                        index2 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(code === "AC"
                        ? _vm.toLocaleCurrency(Math.abs(sectorGross[code]))
                        : _vm.toLocaleCurrency(sectorGross[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N'
                    )),function(code,index3){return _c('td',{key:'3.' + index3},[_vm._v("\n                    "+_vm._s(code !== "EN-PR"
                        ? _vm.toLocaleCurrency(sectorGross[code])
                        : _vm.toLocaleCurrency(
                            sectorGross["EN"] - sectorGross["PR"]
                          ))+"\n                  ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(sectorGross["ONERIVARI"]))+"\n                  ")])],2)}),_vm._v(" "),_c('tr',{staticClass:"totals"},[_c('td',{staticClass:"verticalLine"},[_vm._v("Totale")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'T1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossConsolidatedTotalsBySector[code]
                      ))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N'
                    )),function(code,index2){return _c('td',{key:'T2.' + index2,class:{
                      verticalLine:
                        index2 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossConsolidatedTotalsBySector[code]
                      ))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N'
                    )),function(code,index3){return _c('td',{key:'T3.' + index3},[_vm._v("\n                    "+_vm._s(code !== "EN-PR"
                        ? _vm.toLocaleCurrency(
                            _vm.treasuryGrossConsolidatedTotalsBySector[code]
                          )
                        : _vm.toLocaleCurrency(
                            _vm.treasuryGrossConsolidatedTotalsBySector["EN"] -
                              _vm.treasuryGrossConsolidatedTotalsBySector["PR"]
                          ))+"\n                  ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossConsolidatedTotalsBySector["ONERIVARI"]
                      ))+"\n                  ")])],2)],2)]):_c('div',[_vm._v("Nessun consolidamento da mostrare.")])])]):_vm._e(),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("\n              Giornata Contabile del\n              "+_vm._s(_vm.toLocaleDate(_vm.filter.byCalendar.from))+"\n            ")])]},proxy:true}],null,false,82914713)},[_vm._v(" "),_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableByInsurerBySector).length)?_c('table',[_c('thead',[_c('tr',[_c('th',{class:{ mw: true, verticalLine: true }},[_vm._v("Compagnia")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N',
                      'title'
                    )),function(item){return _c('th',{key:item.code,class:{ sw: true }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Oneri Vari")])],2)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.tableByInsurerBySector),function(sectorGross,id){return _c('tr',{key:'tr-' + id},[_c('td',{staticClass:"verticalLine"},[_vm._v("\n                    "+_vm._s(parseInt(id)
                        ? _vm.getInsurers().find((e) => e.value == id).text
                        : "Indefinita")+"\n                  ")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(sectorGross[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N'
                    )),function(code,index2){return _c('td',{key:'2.' + index2,class:{
                      verticalLine:
                        index2 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(code === "AC"
                        ? _vm.toLocaleCurrency(Math.abs(sectorGross[code]))
                        : _vm.toLocaleCurrency(sectorGross[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N'
                    )),function(code,index3){return _c('td',{key:'3.' + index3},[_vm._v("\n                    "+_vm._s(code !== "EN-PR"
                        ? _vm.toLocaleCurrency(sectorGross[code])
                        : _vm.toLocaleCurrency(
                            sectorGross["EN"] - sectorGross["PR"]
                          ))+"\n                  ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(sectorGross["ONERIVARI"]))+"\n                  ")])],2)}),_vm._v(" "),_c('tr',{staticClass:"totals"},[_c('td',{staticClass:"verticalLine"},[_vm._v("Totale")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'T1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(_vm.treasuryGrossTotalsBySector[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N'
                    )),function(code,index2){return _c('td',{key:'T2.' + index2,class:{
                      verticalLine:
                        index2 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(_vm.treasuryGrossTotalsBySector[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N'
                    )),function(code,index3){return _c('td',{key:'T3.' + index3},[_vm._v("\n                    "+_vm._s(code !== "EN-PR"
                        ? _vm.toLocaleCurrency(_vm.treasuryGrossTotalsBySector[code])
                        : _vm.toLocaleCurrency(
                            _vm.treasuryGrossTotalsBySector["EN"] -
                              _vm.treasuryGrossTotalsBySector["PR"]
                          ))+"\n                  ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossTotalsBySector["ONERIVARI"]
                      ))+"\n                  ")])],2)],2)]):_c('div',[_vm._v("Nessun movimento da mostrare.")])])]),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":'Giornata Contabile del ' +
            _vm.toLocaleDate(_vm.filter.byCalendar.from) +
            ' suddivisa per Codici Mandato',"header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableByMandateCodeBySector).length)?_c('table',[_c('thead',[_c('tr',[_c('th',{class:{ mw: true, verticalLine: true }},[_vm._v("\n                    Compagnia - Mandato\n                  ")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N',
                      'title'
                    )),function(item){return _c('th',{key:item.code,class:{ sw: true }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Oneri Vari")])],2)]),_vm._v(" "),_vm._l((_vm.tableByMandateCodeBySector),function(sectorGrossByMandate,id){return _c('tbody',{key:'TB' + id},_vm._l((sectorGrossByMandate),function(mandateData,mandateCode){return _c('tr',{key:'tr-' + id + '-' + mandateCode},[_c('td',{staticClass:"verticalLine"},[_vm._v("\n                    "+_vm._s(parseInt(id)
                        ? _vm.getInsurers().find((e) => e.value == id).text
                        : "Indefinita")+"\n                    - "+_vm._s(mandateCode)+"\n                  ")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'MD1-' + mandateCode + '-' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(mandateData[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'MD2-' + mandateCode + '-' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(code === "AC"
                        ? _vm.toLocaleCurrency(Math.abs(mandateData[code]))
                        : _vm.toLocaleCurrency(mandateData[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'MD3-' + mandateCode + '-' + index1},[_vm._v("\n                    "+_vm._s(code !== "EN-PR"
                        ? _vm.toLocaleCurrency(mandateData[code])
                        : _vm.toLocaleCurrency(
                            mandateData["EN"] - mandateData["PR"]
                          ))+"\n                  ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(mandateData["ONERIVARI"]))+"\n                  ")])],2)}),0)})],2):_c('div',[_vm._v("Nessun movimento da mostrare.")])])]),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"Sospesi","header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableSospesi).length)?_c('table',[_c('thead',[_c('tr',[_c('th',{key:"SS",class:{
                      sw: true,
                    }},[_vm._v("\n                    Sospesi aperti\n                  ")]),_vm._v(" "),_vm._l((Object.keys(_vm.tableSospesi).filter(
                      (e) => e != 'SS'
                    )),function(key){return _c('th',{key:key,class:{
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(key == "CC+CA"
                        ? "Sospesi regolati di cassa"
                        : key == "TOT"
                        ? "Totale sospesi regolati"
                        : `Sospesi regolati ${
                            _vm.sectorIndex.find((e) => e.code == key).title
                          }`)+"\n                  ")])})],2)]),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(_vm.tableSospesi["SS"]))+"\n                  ")]),_vm._v(" "),_vm._l((Object.entries(
                      _vm.tableSospesi
                    ).filter(([k, v]) => k !== 'SS')),function([key, value]){return _c('td',{key:key},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(value))+"\n                  ")])})],2)])]):_c('div',[_vm._v("Nessun movimento di sospesi da mostrare.")])])]),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"Acconti","header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableAcconti).length)?_c('table',[_c('thead',[_c('tr',[_c('th',{key:"AC",class:{
                      sw: true,
                    }},[_vm._v("\n                    Acconti aperti\n                  ")]),_vm._v(" "),_vm._l((Object.keys(_vm.tableAcconti).filter(
                      (e) => e != 'AC'
                    )),function(key){return _c('th',{key:key,class:{
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(key == "CC+CA"
                        ? "Acconti regolati di cassa"
                        : key == "TOT"
                        ? "Totale acconti regolati"
                        : `Acconti regolati ${
                            _vm.sectorIndex.find((e) => e.code == key).title
                          }`)+"\n                  ")])})],2)]),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(_vm.tableAcconti["AC"]))+"\n                  ")]),_vm._v(" "),_vm._l((Object.entries(
                      _vm.tableAcconti
                    ).filter(([k, v]) => k !== 'AC')),function([key, value]){return _c('td',{key:key},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(value))+"\n                  ")])})],2)])]):_c('div',[_vm._v("Nessun movimento di acconti da mostrare.")])])]),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"Situazione Sospesi","header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(
                Object.keys(_vm.tableSospesi).length &&
                Object.keys(_vm.tableAcconti).length
              )?_c('table',[_c('thead',[_c('tr',[_c('th',{key:"SS_A-AC_A",class:{
                      sw: true,
                    }},[_vm._v("\n                    Sospesi Aperti - Acconti Aperti\n                  ")]),_vm._v(" "),_c('th',{key:"SS_R-AC_R",class:{
                      sw: true,
                    }},[_vm._v("\n                    Sospesi Regolati - Acconti Regolati\n                  ")]),_vm._v(" "),_c('th',{key:"SS_T",class:{
                      sw: true,
                    }},[_vm._v("\n                    Importo Complessivo Sospesi\n                  ")])])]),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.tableSospesi["SS"] - _vm.tableAcconti["AC"]
                      ))+"\n                  ")]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.totSospesiRegolati(_vm.tableSospesi) +
                          _vm.totAccontiRegolati(_vm.tableAcconti)
                      ))+"\n                  ")]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.tableSospesi["SS"] -
                          _vm.tableAcconti["AC"] -
                          (_vm.totSospesiRegolati(_vm.tableSospesi) +
                            _vm.totAccontiRegolati(_vm.tableAcconti))
                      ))+"\n                  ")])])])]):_c('div',[_vm._v("Nessun movimento da mostrare.")])])]),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"Abbuoni","header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableAbbuoni).length)?_c('table',[_c('thead',[_c('tr',_vm._l((Object.keys(_vm.tableAbbuoni)),function(key){return _c('th',{key:key,class:{
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(key == "AB+"
                        ? "Abbuoni passivi"
                        : key == "AB-"
                        ? "Abbuoni attivi"
                        : key)+"\n                  ")])}),0)]),_vm._v(" "),_c('tbody',[_c('tr',_vm._l((Object.entries(_vm.tableAbbuoni)),function([key, value]){return _c('td',{key:key},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(value))+"\n                  ")])}),0)])]):_c('div',[_vm._v("Nessun movimento di abbuoni da mostrare.")])])]),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"Totali incassi non da regolazione sospesi","header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableSameDateBySector).length)?_c('table',[_c('thead',[_c('tr',[_vm._l((_vm.getNonZeroSector(
                      _vm.treasuryGrossSameDateTotalsBySector,
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getNonZeroSector(
                          _vm.treasuryGrossSameDateTotalsBySector,
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                    ")])}),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Cassa")]),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Totale")])],2)]),_vm._v(" "),_c('tbody',[_c('tr',[_vm._l((_vm.getNonZeroSector(
                      _vm.treasuryGrossSameDateTotalsBySector,
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'T1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getNonZeroSector(
                          _vm.treasuryGrossSameDateTotalsBySector,
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossSameDateTotalsBySector[code]
                      ))+"\n                    ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossSameDateTotalsBySector["CC+CA"]
                      ))+"\n                  ")]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.sumAllProps(_vm.treasuryGrossSameDateTotalsBySector)
                      ))+"\n                  ")])],2)])]):_c('div',[_vm._v("\n              Nessun incasso non da regolazione sospesi da mostrare.\n            ")])])]),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"Totali incassi complessivi","header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableOverallBySector).length)?_c('table',[_c('thead',[_c('tr',[_vm._l((_vm.getNonZeroSector(
                      _vm.treasuryGrossOverallTotalsBySector,
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getNonZeroSector(
                          _vm.treasuryGrossOverallTotalsBySector,
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                    ")])}),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Cassa")]),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Totale")])],2)]),_vm._v(" "),_c('tbody',[_c('tr',[_vm._l((_vm.getNonZeroSector(
                      _vm.treasuryGrossOverallTotalsBySector,
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'T1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getNonZeroSector(
                          _vm.treasuryGrossOverallTotalsBySector,
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossOverallTotalsBySector[code]
                      ))+"\n                    ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossOverallTotalsBySector["CC+CA"]
                      ))+"\n                  ")]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.sumAllProps(_vm.treasuryGrossOverallTotalsBySector)
                      ))+"\n                  ")])],2)])]):_c('div',[_vm._v("Nessun incasso complessivo da mostrare.")])])]),_vm._v(" "),(_vm.isProgressive)?_c('b-card',{staticClass:"mt-1",attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("\n              Totale Progressivo + Giornata contabile\n            ")])]},proxy:true}],null,false,2445776659)},[_vm._v(" "),_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableConsolidatedTotalsBySector).length)?_c('table',[_c('thead',[_c('tr',[_c('th',{class:{ mw: true, verticalLine: true }},[_vm._v("Compagnia")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N',
                      'title'
                    )),function(item,index){return _c('th',{key:item.code,class:{
                      verticalLine:
                        index ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                      sw: true,
                    }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N',
                      'title'
                    )),function(item){return _c('th',{key:item.code,class:{ sw: true }},[_vm._v("\n                    "+_vm._s(item)+"\n                  ")])}),_vm._v(" "),_c('th',{class:{ sw: true }},[_vm._v("Oneri Vari")])],2)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.tableConsolidatedTotalsBySector),function(sectorGross,id){return _c('tr',{key:'tr-' + id},[_c('td',{staticClass:"verticalLine"},[_vm._v("\n                    "+_vm._s(parseInt(id)
                        ? _vm.getInsurers().find((e) => e.value == id).text
                        : "Indefinita")+"\n                  ")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(sectorGross[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N'
                    )),function(code,index2){return _c('td',{key:'2.' + index2,class:{
                      verticalLine:
                        index2 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(code === "AC"
                        ? _vm.toLocaleCurrency(Math.abs(sectorGross[code]))
                        : _vm.toLocaleCurrency(sectorGross[code]))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N'
                    )),function(code,index3){return _c('td',{key:'3.' + index3},[_vm._v("\n                    "+_vm._s(code !== "EN-PR"
                        ? _vm.toLocaleCurrency(sectorGross[code])
                        : _vm.toLocaleCurrency(
                            sectorGross["EN"] - sectorGross["PR"]
                          ))+"\n                  ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(sectorGross["ONERIVARI"]))+"\n                  ")])],2)}),_vm._v(" "),_c('tr',{staticClass:"totals"},[_c('td',{staticClass:"verticalLine"},[_vm._v("Totale")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'N',
                      'N',
                      'N',
                      'N'
                    )),function(code,index1){return _c('td',{key:'T1.' + index1,class:{
                      verticalLine:
                        index1 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'N',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossProgressiveTotalsBySector[code]
                      ))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'N',
                      'N',
                      'N'
                    )),function(code,index2){return _c('td',{key:'T2.' + index2,class:{
                      verticalLine:
                        index2 ===
                        _vm.getSectorCodes(
                          _vm.sectorIndex.filter((e) =>
                            e[_vm.fieldONERIVARI]
                              ? e[_vm.fieldONERIVARI].value === 'N'
                              : true
                          ),
                          'Y',
                          'N',
                          'N',
                          'N'
                        ).length -
                          1,
                    }},[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossProgressiveTotalsBySector[code]
                      ))+"\n                  ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                      _vm.sectorIndex.filter((e) =>
                        e[_vm.fieldONERIVARI]
                          ? e[_vm.fieldONERIVARI].value === 'N'
                          : true
                      ),
                      'Y',
                      'Y',
                      'N',
                      'N'
                    )),function(code,index3){return _c('td',{key:'T3.' + index3},[_vm._v("\n                    "+_vm._s(code !== "EN-PR"
                        ? _vm.toLocaleCurrency(
                            _vm.treasuryGrossProgressiveTotalsBySector[code]
                          )
                        : _vm.toLocaleCurrency(
                            _vm.treasuryGrossProgressiveTotalsBySector["EN"] -
                              _vm.treasuryGrossProgressiveTotalsBySector["PR"]
                          ))+"\n                  ")])}),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.toLocaleCurrency(
                        _vm.treasuryGrossProgressiveTotalsBySector["ONERIVARI"]
                      ))+"\n                  ")])],2)],2)]):_c('div',[_vm._v("Nessun totale da mostrare.")])])]):_vm._e(),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"align":"left"}},[_c('b-button-group',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapse-details",arg:"collapse-details"}],staticClass:"my-2 filter-button-group",staticStyle:{"display":"flex","align-items":"center","gap":"5px"}},[_c('h5',[_vm._v("Dettagli")]),_vm._v(" "),_c('span',{staticClass:"when-open"},[_c('b-icon',{attrs:{"icon":"chevron-up","font-scale":"1"}})],1),_vm._v(" "),_c('span',{staticClass:"when-closed"},[_c('b-icon',{attrs:{"icon":"chevron-down","font-scale":"1"}})],1)])],1)],1),_vm._v(" "),_c('b-collapse',{attrs:{"id":"collapse-details"}},[_c('b-card',{staticClass:"mt-1 mb-3",attrs:{"header":"Dettaglio giornata contabile","header-tag":"header"}},[_c('div',{staticClass:"table-wrapper",attrs:{"data-type":"overflow-x"}},[(Object.keys(_vm.tableAllDetails).length)?_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Azioni")]),_vm._v(" "),_c('th',[_vm._v("Compagnia")]),_vm._v(" "),_c('th',[_vm._v("Data")]),_vm._v(" "),_c('th',[_vm._v("Polizza")]),_vm._v(" "),_c('th',[_vm._v("Operazione")]),_vm._v(" "),_c('th',[_vm._v("Contraente")]),_vm._v(" "),_c('th',{class:{ verticalLine: true }},[_vm._v("Produttore")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                        _vm.sectorIndex.filter((e) =>
                          e[_vm.fieldONERIVARI]
                            ? e[_vm.fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N',
                        'title'
                      )),function(item,index){return _c('th',{key:item.code,class:{
                        verticalLine:
                          index ===
                          _vm.getSectorCodes(
                            _vm.sectorIndex.filter((e) =>
                              e[_vm.fieldONERIVARI]
                                ? e[_vm.fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }},[_vm._v("\n                      "+_vm._s(item)+"\n                    ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                        _vm.sectorIndex.filter((e) =>
                          e[_vm.fieldONERIVARI]
                            ? e[_vm.fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N',
                        'title'
                      )),function(item,index){return _c('th',{key:item.code,class:{
                        verticalLine:
                          index ===
                          _vm.getSectorCodes(
                            _vm.sectorIndex.filter((e) =>
                              e[_vm.fieldONERIVARI]
                                ? e[_vm.fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }},[_vm._v("\n                      "+_vm._s(item)+"\n                    ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                        _vm.sectorIndex.filter((e) =>
                          e[_vm.fieldONERIVARI]
                            ? e[_vm.fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N',
                        'title'
                      )),function(item){return _c('th',{key:item.code},[_vm._v("\n                      "+_vm._s(item)+"\n                    ")])}),_vm._v(" "),_c('th',[_vm._v("Oneri Vari")])],2)]),_vm._v(" "),_vm._l((_vm.tableAllDetails),function(data,index){return _c('tbody',{key:index},[_c('tr',{class:{ virtual: data.virtual }},[_c('td',[(_vm.canConsolidate && !data.virtual)?_c('b-button',{staticClass:"mt-1 mr-1",attrs:{"size":"sm","variant":"lisaweb","title":"Modifica"},on:{"click":function($event){return _vm.onEdit(data)}}},[_c('b-icon',{attrs:{"icon":"pencil-square"}})],1):_vm._e()],1),_vm._v(" "),_c('td',[_vm._v("\n                      "+_vm._s(parseInt(data.insurer_id)
                          ? _vm.getInsurers().find(
                              (e) => e.value == data.insurer_id
                            ).text
                          : "Indefinita")+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      "+_vm._s(_vm.toLocaleDate(data.book_date))+"\n                    ")]),_vm._v(" "),_c('td',[_vm._v("\n                      "+_vm._s(data.various_accountings &&
                        data.various_accountings.length
                          ? data.various_accountings[0].pivot
                              .insurance_policy_number
                          : data.insurance_ancillaries &&
                            data.insurance_ancillaries.length
                          ? data.insurance_ancillaries[0].insurance_policy
                            ? data.insurance_ancillaries[0].insurance_policy
                                .number
                            : ""
                          : "")+"\n                    ")]),_vm._v(" "),_c('td',[_c('span',[_vm._v("\n                        "+_vm._s(_vm.renderOperation(data))+"\n                      ")])]),_vm._v(" "),_c('td',[_c('span',[_vm._v("\n                        "+_vm._s(_vm.getRegistry(data))+"\n                      ")])]),_vm._v(" "),_c('td',{staticClass:"verticalLine"},[_vm._v("\n                      "+_vm._s(data.brokers.length
                          ? _vm.getSalesmen().find(
                              (e) => e.value == data.brokers[0].id
                            )
                            ? _vm.getSalesmen().find(
                                (e) => e.value == data.brokers[0].id
                              ).text
                            : "--"
                          : "--")+"\n                    ")]),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                        _vm.sectorIndex.filter((e) =>
                          e[_vm.fieldONERIVARI]
                            ? e[_vm.fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'N',
                        'N',
                        'N',
                        'N'
                      )),function(code,index1){return _c('td',{key:'1.' + index1,class:{
                        verticalLine:
                          index1 ===
                          _vm.getSectorCodes(
                            _vm.sectorIndex.filter((e) =>
                              e[_vm.fieldONERIVARI]
                                ? e[_vm.fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'N',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }},[_vm._v("\n                      "+_vm._s(_vm.toLocaleCurrency(
                          _vm.getGrossByCode(data.entry_details, code)
                        ))+"\n                    ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                        _vm.sectorIndex.filter((e) =>
                          e[_vm.fieldONERIVARI]
                            ? e[_vm.fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'N',
                        'N',
                        'N'
                      )),function(code,index2){return _c('td',{key:'2.' + index2,class:{
                        verticalLine:
                          index2 ===
                          _vm.getSectorCodes(
                            _vm.sectorIndex.filter((e) =>
                              e[_vm.fieldONERIVARI]
                                ? e[_vm.fieldONERIVARI].value === 'N'
                                : true
                            ),
                            'Y',
                            'N',
                            'N',
                            'N'
                          ).length -
                            1,
                      }},[_vm._v("\n                      "+_vm._s(_vm.toLocaleCurrency(
                          _vm.getGrossByCode(data.entry_details, code)
                        ))+"\n                    ")])}),_vm._v(" "),_vm._l((_vm.getSectorCodes(
                        _vm.sectorIndex.filter((e) =>
                          e[_vm.fieldONERIVARI]
                            ? e[_vm.fieldONERIVARI].value === 'N'
                            : true
                        ),
                        'Y',
                        'Y',
                        'N',
                        'N'
                      )),function(code,index3){return _c('td',{key:'3.' + index3},[_vm._v("\n                      "+_vm._s(code !== "EN-PR"
                          ? _vm.toLocaleCurrency(
                              _vm.getByPropVal(
                                data.entry_details,
                                "treasury.sector.code",
                                code,
                                "gross"
                              )
                            )
                          : _vm.toLocaleCurrency(
                              _vm.getByPropVal(
                                data.entry_details,
                                "treasury.sector.code",
                                "EN",
                                "gross"
                              ) -
                                _vm.getByPropVal(
                                  data.entry_details,
                                  "treasury.sector.code",
                                  "PR",
                                  "gross"
                                )
                            ))+"\n                    ")])}),_vm._v(" "),_c('td',[_vm._v("\n                      "+_vm._s(_vm.toLocaleCurrency(
                          _vm.getOneriVariGross(data.entry_details)
                        ))+"\n                    ")])],2)])})],2):_vm._e()])])],1),_vm._v(" "),_c('div',{staticClass:"consolida-btn-wrapper"},[(_vm.canConsolidate)?_c('b-button',{attrs:{"variant":"lisaweb"},on:{"click":function($event){return _vm.saveConsolitated()}}},[_vm._v("Consolida\n          ")]):_vm._e()],1)],1):_c('div',[_vm._v("Dati non disponibili")])])],1),_vm._v(" "),(!_vm.isLoading && !_vm.isConsolidateLoading)?_c('consolidation-calendar-modal',{attrs:{"max":_vm.consolidatedAt},on:{"view":_vm.onDrawFoglioQuadrature,"reload":function($event){_vm.previously_consolidated_at = null;
      _vm.filter.byCalendar.from = null;
      _vm.resetData();
      _vm.onLoadLastConsolidated();}}}):_vm._e()],1):_c('div',{staticClass:"mt-3"},[_c('b-icon',{attrs:{"icon":"info-circle","scale":"1.00"}}),_vm._v("\n  Non hai i permessi\n")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }